import React from 'react';
import {
  ChakraProvider,
  Box,
  theme,
} from '@chakra-ui/react';
import Jumbotron from './Jumbotron';
import MainBody from './MainBody';
import NavBar from './comps/NavBar';
import './css/app.css';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box fontSize="xl">
        <NavBar />
        <Jumbotron />
        <MainBody />
      </Box>
    </ChakraProvider>
  );
}

export default App;
