import { Box, SimpleGrid, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import { SiHive } from 'react-icons/si'
import { ActionButton } from './comps/ActionButton'
import { PricingCard } from './comps/PricingCard'

const Courses = () => (
    <Box
        as="section"
        bg={useColorModeValue('gray.50', 'gray.800')}
        py="14"
        px={{
            base: '4',
            md: '8',
        }}
    >
        <SimpleGrid
            columns={{
                base: 1,
                lg: 1,
            }}
            spacing={{
                base: '8',
                lg: '0',
            }}
            maxW="8xl"
            mx="auto"
            justifyItems="center"
            alignItems="center"
        >
            <PricingCard
                zIndex={1}
                isPopular
                transform={{
                    lg: 'scale(1.05)',
                }}
                data={{
                    price: '$2999',
                    name: 'Zero to Hero',
                    features: [
                        'Live 1:1 Bootcamp',
                        'Unlimited 1:1 Face2Face Live sessions ',
                        '4 Bonus Live Mock Interviews with Community Experts',
                        'Access to Exclusive Discord Channels',
                        'All Live and Private Sessions',
                        'Full Source code to all Workshops',
                        'Customized for your needs',
                        'Noob Freindly',
                        'Money Back Guarantee',
                    ],
                }}
                icon={SiHive}
                button={<ActionButton>Enroll Now</ActionButton>}
            />
        </SimpleGrid>
    </Box>
)

export default Courses