import {
    Container,
    Stack,
    Flex,
    Box,
    Heading,
    Text,
    Button,
    Image,
    useColorModeValue,
    Link
} from '@chakra-ui/react';
import img from './img.png'

export default function Jumbotron() {
    return (
        <Container maxW={'7xl'}>
            <Stack
                align={'center'}
                spacing={{ base: 8, md: 10 }}
                py={{ base: 20, md: 28 }}
                direction={{ base: 'column', md: 'row' }}>
                <Stack flex={1} spacing={{ base: 5, md: 10 }}>
                    <Heading
                        lineHeight={1.1}
                        fontWeight={700}
                        color={'#ffde59'}
                        fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
                        <Text>
                            CodeStrap
                        </Text>

                    </Heading>
                    <Text color={'gray.500'}>
                        Ultimate community to take your coding ambitions to the moon 🚀
                    </Text>
                    <Stack
                        spacing={{ base: 4, sm: 6 }}
                        direction={{ base: 'column', sm: 'row' }}>
                        <Link
                            _hover={{ textDecoration: 'none' }}
                            href={'https://discord.gg/xrsxthmPrs'} target={'_blank'}>
                            <Button

                                rounded={'full'}
                                size={'lg'}
                                fontWeight={'normal'}
                                bg={'#ffde59'}
                                _hover={{ bg: 'red.500' }}>
                                Join our Discord
                            </Button>
                        </Link>


                    </Stack>
                </Stack>
                <Flex
                    flex={1}
                    justify={'center'}
                    align={'center'}
                    position={'relative'}
                    w={'full'}>
                    <Blob
                        w={'150%'}
                        h={'150%'}
                        position={'absolute'}
                        top={'-20%'}
                        left={0}
                        zIndex={-1}
                        color={useColorModeValue('red.50', 'red.400')}
                    />
                </Flex>
            </Stack>
        </Container >
    );
}
export const Blob = (props) => {
    return (
        <Box className='imgBox'>
            <Image src={img} alt='CodeStrap' />
        </Box>
    );
};