import { Box, } from '@chakra-ui/react';
import React from 'react';

import './mainbody.css';
import Courses from './Courses';

function MainBody() {
    return (
        <Box className='mainBody'>
            <Courses />
        </Box>

    );
}

export default MainBody;
