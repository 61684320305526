import {
    Box,
    Flex,
    HStack,
    Link,
    IconButton,
    useDisclosure,
    useColorModeValue,
    Stack,
    Image
} from '@chakra-ui/react';
import Logo from '../logo.png';
import { HamburgerIcon, CloseIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import '../css/navbar.css';


const Links = [
    { name: 'Discord', icon: <ExternalLinkIcon />, url: 'https://discord.gg/xrsxthmPrs' },
    { name: 'Reviews', url: 'google.com' },
    { name: 'Contact Us', url: 'https://qjzqr6dm332.typeform.com/to/v1M25yep' }];

const NavLink = ({ children, url }) => (
    <Link
        px={2}
        py={1}
        rounded={'md'}
        _hover={{
            textDecoration: 'none',
            bg: useColorModeValue("#0d4a78", "#0d4a78")
        }}
        href={url} target={'_blank'}>
        {children}
    </Link>
);

function NavBar() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Box className='mainnavbar' px={4}>
                <Flex h={16} alignItems={'center'} justifyContent='space-between'>

                    <HStack>
                        <Box><Image boxSize='80px' src={Logo} /></Box>
                    </HStack>
                    <Flex alignItems={'center'}>

                        <HStack spacing={8} alignItems={'center'} >
                            <HStack
                                as={'nav'}
                                spacing={4}
                                display={{ base: 'none', md: 'flex' }}>
                                {Links.map((link) => (
                                    <NavLink url={link.url} key={link.name}> <Box className='indiLink'>{link.name}{link.icon ? link.icon : null}</Box></NavLink>

                                ))}
                            </HStack>
                        </HStack>

                    </Flex>
                    <IconButton
                        size={'md'}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={'Open Menu'}
                        display={{ md: 'none' }}
                        onClick={isOpen ? onClose : onOpen}
                    />

                </Flex>

                {isOpen ? (
                    <Box pb={4} display={{ md: 'none' }}>
                        <Stack as={'nav'} spacing={4}>
                            {Links.map((link) => (
                                <NavLink url={link.url} key={link.name}>{link.name}</NavLink>
                            ))}
                        </Stack>
                    </Box>
                ) : null}
            </Box>

        </>
    );
}

export default NavBar;